import 'owl.carousel';
import 'slick-carousel';
import 'magnific-popup';
import moment from 'moment';

jQuery(document).ready(function ($) {
	// Menu
	$('a[href^="#"]').on('click', function (e) {
		e.preventDefault();
		const href = $(this).attr('href');
		$('html, body').animate({
			scrollTop: $(href).offset().top
		}, 2000);
	});
	// Music
	$('.slider-music').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		mobileFirst: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
		// responsive: [
		// 	{
		// 		breakpoint: 992,
		// 		settings: {
		// 			slidesToShow: 3,
		// 		}
		// 	}
		// ]
	});
	// Videos
	$('#watch .owl-carousel').owlCarousel({
		items: 1,
		loop: true,
		dots: false,
		nav: true,
		margin: 20,
		startPosition: 1
	});

	//Video Selector
	// $('#watch').on('click', '.video-item a', function (e) {
	// 	e.preventDefault();
	// 	const title = $(this).attr('data-video-title');
	// 	const id = $(this).attr('data-video-id');
	// 	$('#video_player iframe').attr('src', 'https://www.youtube.com/embed/' + id + '?autoplay=1');
	// 	$('#video_player span').html(title);
	// });

	// Follow
	$.ajax({
		url: 'https://www.juicer.io/api/feeds/sephschlueter',
		method: 'GET',
		dataType: 'json',
		success: function (data) {
			if (data.posts.items.length > 0) {
				let i = 0;
				let html = '';
				for (let item of data.posts.items) {
					html += '<div>';
					html += '<a href="' + item.full_url + '" target="_blank">';
					html += '<img src="' + item.image + '" alt="' + item.external_id + '" />';
					html += '</a>';
					html += '</div>';
					i++; 
					if (i >= 8) {
						break;
					} 
				}
				$('#instagram_posts').html(html);
			}
		},
		error: function () {
			alert('An error has occurred fetching Instagram posts!');
		}
	});

	// $('.tour-btn').on('click', function (e) {
	// 	e.preventDefault();
	// 	$('#events').addClass('seeall');
	// 	$(this).hide();
	// })

	// Events
	$.ajax({
		url: 'https://rest.bandsintown.com/artists/id_15532941/events?app_id=45PRESS_Seph',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert('Error fetching events!');
		},
		success: data => {
			console.log(data);
			const events = $('#events');
			let html = '';
			if (data.length) {
				for (let event of data) {
					let location = event.venue.city + ', ' + event.venue.region;
					if (location === ', ') { location = ''; }
					if (event.venue.name.toLowerCase() === 'streaming live') { location = 'Online'; }
					event.lineup.splice('Seph Schlueter', 1)
					let guest = event.lineup.join(', ');
					html += '<div class="event">';
					html += '<div class="event-date">' + moment(event.datetime).format('MMMM D, Y') + '<div class="support">' + guest + '</div>' + '</div>';
					html += '<div class="event-info">';
					html += '<div class="event-location">' + '<div class="event-venue">' + event.venue.name + '</div>' + location + '</div>';
					html += '</div>';
					html += '<div class="event-links">';
					for (let offer of event.offers) {
						html += '<a href="' + offer.url + '" target="_blank" class="btn btn-light">' + offer.type + '</a>';
					}
					html += '</div>';
					html += '</div>';
				}
				events.html(html);
			} else {
				events.html('No upcoming events.');
			}
		}
	});

	// POPUP
	$.magnificPopup.open({
    items: {
      src: '#newsletter-popup'
    },
    type: 'inline',
    fixedContentPos: true
  })
});
